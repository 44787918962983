import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { TeamReduxType, TeamState, Status } from "./types";
import {
    getTeam,
    getTeamIdle,
    getTeamSuccess,
} from "./actions";

export const initialState: TeamState = {
    status: 'IDLE',
    team: null
};

const status = handleActions<Status>(
    {
        [getTeam]: () => 'LOADING',
        [getTeamSuccess]: () => 'SUCCESS',
        [getTeamIdle]: () => 'IDLE',
    },
    initialState.status
);

const team = handleActions(
    {
        [getTeamSuccess]: (state, action: { payload: { team: TeamReduxType } } ) => action.payload.team
    },
    initialState.team
);

export const teamReducer = combineReducers({
  status,
  team,
});
