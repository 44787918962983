import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getLayoutRequest } from './api';

import { initDataFailure, initData, initDataSuccess } from './actions';
import { Locales } from 'types';

function* initDataSaga(action: { payload: Locales; type: string }) {
    try {
        const { payload } = action;
        const locale = payload;
        const [header, footer]: any[] = yield all([
            call(getLayoutRequest, { layout: 'header', locale }),
            call(getLayoutRequest, { layout: 'footer', locale }),
        ]);
        let headerData = {
            logo: header.attributes.Logo.data.attributes.url,
            logo_black: header.attributes.LogoBlack.data.attributes.url,
            menu: header.attributes.Menu,
            button: {
                title: header.attributes.Button.Title,
                link: header.attributes.Button.Link,
            },
        };
        let footerData = {
            logo: footer.attributes.Logo.data.attributes.url,
            secondColumn: footer.attributes.SecondColumn,
            firstColumn: footer.attributes.FirstColumn,
            copyright: footer.attributes.Copyright,
            description: footer.attributes.Description,
            button: {
                title: footer.attributes.Button.Title,
                link: footer.attributes.Button.Link,
            },
        };
        yield put(
            initDataSuccess({
                header: headerData,
                footer: footerData,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(initDataFailure(error));
    }
}

export function* initDataWatcher() {
    yield takeEvery(initData.toString(), initDataSaga);
}
