import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { CareersReduxType, CareersState, Status } from "./types";
import {
    getCareers,
    getCareersIdle,
    getCareersSuccess,
} from "./actions";

export const initialState: CareersState = {
    status: 'IDLE',
    careers: null
};

const status = handleActions<Status>(
    {
        [getCareers]: () => 'LOADING',
        [getCareersSuccess]: () => 'SUCCESS',
        [getCareersIdle]: () => 'IDLE',
    },
    initialState.status
);

const careers = handleActions(
    {
        [getCareersSuccess]: (state, action: { payload: { careers: CareersReduxType } } ) => action.payload.careers
    },
    initialState.careers
);

export const careersReducer = combineReducers({
  status,
  careers,
});
