import { useContext, useEffect } from 'react';
import { call, takeEvery, put, all, getContext } from 'redux-saga/effects';

import { getHomeRequest } from './api';

import {
    getHomeFailure,
    getHomeIdle,
    getHome,
    getHomeSuccess,
} from './actions';

import { Locales } from 'types';

function* homeSaga(action: { payload: Locales; type: string }) {
    try {
        const { payload } = action;
        const locale = payload;
        const home: { id: string; attributes: any } = yield call(
            getHomeRequest,
            { locale }
        );
        let homeData = {};
        if (locale === 'en') {
            homeData = {
                title: home.attributes.Title,
                subTitle: home.attributes.SubTitle,
                button: home.attributes.Button,
                description: home.attributes.Description,
                ourFocus:
                    (home.attributes.OurFocus &&
                        home.attributes.OurFocus.length > 0 &&
                        home.attributes.OurFocus.map((item: any) => {
                            return {
                                key: item.id,
                                number: item.Number,
                                title: item.Title,
                                description: item.Description,
                            };
                        })) ||
                    [],
                ourFocusTitle: home.attributes.OurFocusTitle,
                ourFocusButton: home.attributes.OurFocusButton,
                whoWeAre: home.attributes.WhoWeAre,
                openPositions: home.attributes.OpenPositions,
                openPositionsList:
                    (home.attributes.OpenPositionsList &&
                        home.attributes.OpenPositionsList.length > 0 &&
                        home.attributes.OpenPositionsList.map((item: any) => {
                            return {
                                key: item.id,
                                title: item.Title,
                                location: item.Location,
                                labelLink: item.LinkCaption,
                                link: item.LinkUrl,
                            };
                        })) ||
                    [],
                whoWeAreList:
                    (home.attributes.WhoWeAreList &&
                        home.attributes.WhoWeAreList.length > 0 &&
                        home.attributes.WhoWeAreList.map((item: any) => {
                            return {
                                key: item.id,
                                name: item.Name,
                                position: item.Position,
                                photo:
                                    item.Photo &&
                                    item.Photo.data &&
                                    item.Photo.data.attributes &&
                                    item.Photo.data.attributes.url,
                            };
                        })) ||
                    [],
            };
        }
        if (locale === 'ru') {
            homeData = {
                title: home.attributes.Title,
                subTitle: home.attributes.SubTitle,
                button: home.attributes.Button,
                description: home.attributes.Description,
                whoWeAre: home.attributes.WhoWeAre,
                openPositionsTitle: home.attributes.OurFocusTitle,
                openPositions: home.attributes.OpenPositions,
                openPositionsNotFound: home.attributes.OpenPositionsNotFound,
                openPositionsList:
                    (home.attributes.OpenPositionsList &&
                        home.attributes.OpenPositionsList.length > 0 &&
                        home.attributes.OpenPositionsList.map((item: any) => {
                            return {
                                key: item.id,
                                title: item.Title,
                                location: item.Location,
                                description: item.Description,
                                labelLink: item.LinkCaption,
                                link: item.LinkUrl,
                            };
                        })) ||
                    [],
                whoWeAreList:
                    (home.attributes.WhoWeAreList &&
                        home.attributes.WhoWeAreList.length > 0 &&
                        home.attributes.WhoWeAreList.map((item: any) => {
                            return {
                                key: item.id,
                                name: item.Name,
                                position: item.Position,
                                photo:
                                    item.Photo &&
                                    item.Photo.data &&
                                    item.Photo.data.attributes &&
                                    item.Photo.data.attributes.url,
                            };
                        })) ||
                    [],
                servicesList:
                    (home.attributes.ServicesList &&
                        home.attributes.ServicesList.length > 0 &&
                        home.attributes.ServicesList.map((item: any) => {
                            return {
                                key: item.id,
                                title: item.Title,
                                description: item.Description,
                                icon: item.Logo.data.attributes.url,
                            };
                        })) ||
                    [],
            };
        }
        yield put(
            getHomeSuccess({
                home: homeData,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getHomeFailure(error));
    }
}

export function* homeWatcher() {
    yield takeEvery(getHome.toString(), homeSaga);
}
