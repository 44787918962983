import { call, takeEvery, put } from 'redux-saga/effects';

import { getTeam, getTeamFailure, getTeamSuccess } from './actions';
import { getTeamRequest } from './api';
import { TeamReduxType } from './types';
import { Locales } from 'types';

function* getSaga(action: { payload: Locales; type: string }) {
    try {
        const { payload } = action;
        const locale = payload;
        const team: TeamReduxType = yield call(getTeamRequest, { locale });
        yield put(
            getTeamSuccess({
                team,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getTeamFailure(error));
    }
}

export function* teamWatcher() {
    yield takeEvery(getTeam.toString(), getSaga);
}
