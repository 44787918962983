import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { State } from '../../types';
import { LayoutFooterState } from '../../store/ducks/app/types';
import { domain, Context } from '../../config';

export function Footer() {
    const { pathname } = useLocation();
    const footer: LayoutFooterState = useSelector(
        (state: State) => state.layouts.footer
    );
    const { locale } = useContext(Context);
    return (
        <>
            {locale === 'en' && (
                <footer className="footer">
                    <div className="footer__container">
                        <a href="/" className="footer__logo">
                            <img src={`${domain}${footer.logo}`} alt="logo" />
                        </a>
                        <div className="footer__info">
                            <div className="footer__col">
                                <p
                                    className="footer__text smallText smallText--white"
                                    dangerouslySetInnerHTML={{
                                        __html: footer.description,
                                    }}
                                />
                                <a
                                    href={footer.button.link}
                                    className="footer__btn button button--medium"
                                >
                                    {footer.button.title}
                                </a>
                            </div>
                            <div className="footer__col">
                                <pre>
                                    <p
                                        className="footer__text smallText smallText--white"
                                        dangerouslySetInnerHTML={{
                                            __html: footer.firstColumn,
                                        }}
                                    />
                                </pre>
                            </div>
                            <div className="footer__col">
                                <pre>
                                    <p
                                        className="footer__text smallText smallText--white"
                                        dangerouslySetInnerHTML={{
                                            __html: footer.secondColumn,
                                        }}
                                    />
                                </pre>
                            </div>
                        </div>
                        <p className="footer__copy smallText--white">
                            {moment().format('YYYY')}, {footer.copyright}
                        </p>
                    </div>
                </footer>
            )}
            {locale === 'ru' && (
                <footer className="footer footer--ru">
                    <div className="footer__container">
                        <div className="footer__copy smallText--black">
                            {footer.copyright} {moment().format('YYYY')}
                        </div>
                        <div className="footer__info">
                            {footer.description}&nbsp;&nbsp;
                            <a href={footer.button.link}>
                                {footer.button.title}
                            </a>
                        </div>
                    </div>
                </footer>
            )}
        </>
    );
}
