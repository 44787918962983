import { useContext } from 'react';
import fetch from 'isomorphic-fetch';
import { apiUrl, token } from '../../../../config';
import { Locales } from 'types';

export const getLayoutRequest = (props: {
    layout: string;
    locale: Locales;
}) => {
    const { layout, locale } = props;
    const url = `${apiUrl}${layout}?locale=${locale}&populate=*`;
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data.data);
};
