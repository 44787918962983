import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { ourFocusFailure, ourFocusIdle, ourFocus, ourFocusSuccess } from './actions';
import {
    Status,
    OurFocusState,
    PayloadOurFocusType
} from './types';

export const initialState: OurFocusState = {
    status: 'IDLE',
    data: {
        steps: [],
        titlePortfolio: "",
        titleOurFocus: "",
        portfolio: []
    }
};



const status = handleActions<Status>(
    {
        [ourFocus.toString()]: () => 'LOADING',
        [ourFocusSuccess.toString()]: () => 'SUCCESS',
        [ourFocusIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [ourFocusSuccess.toString()]: (state, action: { payload: PayloadOurFocusType }) => {
            return action.payload.our_focus
        },
    },
    initialState.data
);


export const our_focus = combineReducers({
    status,
    data
});
