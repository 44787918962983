import fetch from 'isomorphic-fetch';
import { apiUrl, token } from '../../../../config';
import { Locales } from 'types';
export const getHomeRequest = (props: { locale: Locales }) => {
    const { locale } = props;
    const url = `${apiUrl}home?locale=${locale}&populate[WhoWeAreList][populate]=*&populate[Button][populate]=*&populate[OurFocus][populate]=*&populate[OurFocusButton][populate]=*&populate[WhoWeAre][populate]=*&populate[OpenPositions][populate]=*&populate[OpenPositionsList][populate]=*&populate[ServicesList][populate]=*&populate[OpenPositionsNotFound][populate]=*`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data.data);
};
