import { call, takeEvery, put, all } from 'redux-saga/effects';

import { getOurFocusRequest } from './api';

import {
    ourFocusFailure,
    ourFocusIdle,
    ourFocus,
    ourFocusSuccess,
} from './actions';

import { Locales } from 'types';

function* getSaga(action: { payload: Locales; type: string }) {
    try {
        const { payload } = action;
        const locale = payload;
        const our_focus: {
            id: string;
            attributes: any;
        } = yield call(getOurFocusRequest, { locale });
        let ourFocusData = {
            titlePortfolio: our_focus.attributes.TitlePortfolio,
            titleOurFocus: our_focus.attributes.TitleOurFocus,
            steps:
                (our_focus.attributes.Steps &&
                    our_focus.attributes.Steps.length > 0 &&
                    our_focus.attributes.Steps.map((item: any) => {
                        return {
                            key: item.id,
                            number: item.Number,
                            title: item.Title,
                            description: item.Description,
                            steps:
                                (item.Steps &&
                                    item.Steps.length > 0 &&
                                    item.Steps.map((step: any) => {
                                        return {
                                            number: step.Number,
                                            key: step.id,
                                            description: step.Description,
                                        };
                                    })) ||
                                [],
                        };
                    })) ||
                [],
            portfolio:
                (our_focus.attributes.Portfolio &&
                    our_focus.attributes.Portfolio.length > 0 &&
                    our_focus.attributes.Portfolio.map((item: any) => {
                        return {
                            key: item.id,
                            button: item.Button,
                            description: item.Description,
                            title: item.Title,
                            subTitle: item.SubTitle,
                            logo: item.Logo.data.attributes.url,
                        };
                    })) ||
                [],
        };
        yield put(
            ourFocusSuccess({
                our_focus: ourFocusData,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(ourFocusFailure(error));
    }
}

export function* ourFocusWatcher() {
    yield takeEvery(ourFocus.toString(), getSaga);
}
