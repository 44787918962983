import { fork, all } from 'redux-saga/effects';

import { routerSaga } from './ducks/router/saga';
import { initDataWatcher } from './ducks/app/saga';
import { careersWatcher } from './ducks/careers/saga';
import { ourFocusWatcher } from "./ducks/our_focus/saga";
import { homeWatcher } from "./ducks/home/saga";
import { teamWatcher } from './ducks/who_we_are/saga';

export default function* rootSaga() {
    yield all([
        fork(routerSaga),
        fork(initDataWatcher),
        fork(careersWatcher),
        fork(ourFocusWatcher),
        fork(homeWatcher),
        fork(teamWatcher),
    ]);
}
