import { createActions } from "redux-actions";
import { PayloadHomeType } from "./types";

const { getHomeFailure, getHomeIdle, getHome } = createActions(
  "GET_HOME_FAILURE",
  "GET_HOME_IDLE",
  "GET_HOME"
);

const { getHomeSuccess } = createActions<PayloadHomeType>("GET_HOME_SUCCESS");

export { getHomeFailure, getHomeIdle, getHome, getHomeSuccess };
