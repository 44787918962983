import { call, takeEvery, put } from 'redux-saga/effects';

import { getCareers, getCareersFailure, getCareersSuccess } from './actions';
import { getCareersRequest } from './api';
import { CareersReduxType } from './types';
import { Locales } from 'types';

function* getSaga(action: { payload: Locales; type: string }) {
    try {
        const { payload } = action;
        const locale = payload;

        const careers: CareersReduxType = yield call(getCareersRequest, {
            locale,
        });
        yield put(
            getCareersSuccess({
                careers,
            })
        );
    } catch (error) {
        console.log(error);
        yield put(getCareersFailure(error));
    }
}

export function* careersWatcher() {
    yield takeEvery(getCareers.toString(), getSaga);
}
