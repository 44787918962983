import loadable from '@loadable/component';
import { Locales, RouterFetchDataArgs } from 'types';

import { initData } from './store/ducks/app/actions';
import { getCareers } from './store/ducks/careers/actions';
import { ourFocus } from 'store/ducks/our_focus/actions';
import { getHome } from './store/ducks/home/actions';
import { getTeam } from './store/ducks/who_we_are/actions';

const HomeEn = loadable(() => import('./pages/HomeEn/HomeEn'));
const HomeRu = loadable(() => import('./pages/HomeRu/HomeRu'));
const OurFocus = loadable(() => import('./pages/OurFocus/OurFocus'));
const WhoWeAre = loadable(() => import('./pages/WhoWeAre/WhoWeAre'));
const Careers = loadable(() => import('./pages/Careers/Careers'));

/**
 * Routes are moved to a separate file,
 * so that you can use the asyncFetchData method on the component on the server (by url path)
 * which load all the necessary data for rendering the page.
 */

const getRoutes = (hostLang: Locales) => {
    return [
        {
            path: '/',
            component: hostLang === 'en' ? HomeEn : HomeRu,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(initData(hostLang));
                dispatch(getHome(hostLang));
            },
        },
        {
            path: '/our_focus',
            component: OurFocus,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(initData(hostLang));
                dispatch(ourFocus(hostLang));
            },
        },
        {
            path: '/who_we_are',
            component: WhoWeAre,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(initData(hostLang));
                dispatch(getTeam(hostLang));
            },
        },
        {
            path: '/careers',
            component: Careers,
            exact: true,
            fetchData({ dispatch }: RouterFetchDataArgs) {
                dispatch(initData(hostLang));
                dispatch(getCareers(hostLang));
            },
        },
    ];
};
export default getRoutes;
