import { createActions } from 'redux-actions';

const {
    getCareers,
    getCareersSuccess,
    getCareersFailure,
    getCareersIdle,
}: {
    getCareers?: any;
    getCareersSuccess?: any;
    getCareersFailure?: any;
    getCareersIdle?: any;
} = createActions(
    'GET_CAREERS',
    'GET_CAREERS_SUCCESS',
    'GET_CAREERS_FAILURE',
    'GET_CAREERS_IDLE'
);

export {
    getCareers,
    getCareersSuccess,
    getCareersFailure,
    getCareersIdle,
};
