import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { Header, Footer } from 'components';
import getRoutes from '../../routes';
import '../../../static/styles/style.css';
import '../../../static/styles/custom.css';
import '../../../static/styles/animations.css';
import { initData } from 'store/ducks/app/actions';
import { State, Locales, ContextType } from '../../types';
import { Context, defaultContext, defaultLang } from '../../config';

function App(props: { hostLang: Locales }) {
    const { hostLang } = props;

    return (
        <div className={cn('wrapper')}>
            <Context.Provider value={defaultContext[hostLang || defaultLang]}>
                <Header />
                <Switch>
                    {getRoutes(hostLang || defaultLang).map(({ fetchData, ...routeProps }) => {
                        return <Route key={routeProps.path} {...routeProps} />;
                    })}
                </Switch>
                <Footer />
            </Context.Provider>
        </div>
    );
}

const Component = App;

export { Component as App };
