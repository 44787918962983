import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getHomeFailure, getHomeIdle, getHome, getHomeSuccess } from './actions';
import {
    Status,
    HomeState,
    PayloadHomeType
} from './types';

export const initialState: HomeState = {
    status: 'IDLE',
    data: {
        title: "",
        subTitle: "",
        button: {Title: "", Link: ""},
        description: "",
        ourFocus: [],
        openPositionsTitle: "",
        ourFocusTitle: "",
        ourFocusButton: {Title: "", Link: ""},
        whoWeAre: {Title: "", Description: "", LinkCaption: "", LinkUrl: ""},
        openPositions: {Title: "", Description: "", LinkCaption: "", LinkUrl: ""},
        openPositionsNotFound: {Title: "", Description: "", LinkCaption: "", LinkUrl: ""},
        openPositionsList: [],
        whoWeAreList: [],
        servicesList: []
    }
};



const status = handleActions<Status>(
    {
        [getHome.toString()]: () => 'LOADING',
        [getHomeSuccess.toString()]: () => 'SUCCESS',
        [getHomeIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const data = handleActions(
    {
        [getHomeSuccess.toString()]: (state, action: { payload: PayloadHomeType }) => {
            return action.payload.home
        },
    },
    initialState.data
);


export const home = combineReducers({
    status,
    data
});
