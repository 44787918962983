import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { initDataIdle, initData, initDataSuccess } from './actions';
import {
    Status,
    LayoutsState,
    PayloadInitDataType
} from './types';

export const initialState: LayoutsState = {
    status: 'IDLE',
    header: { logo: "", logo_black: "", menu: [], button: {title: "", link: ""} },
    footer: {
        logo: "",
        secondColumn: "",
        firstColumn: "",
        copyright: "",
        description: "",
        button: {title: "", link: ""}
    },
};



const status = handleActions<Status>(
    {
        [initData.toString()]: () => 'LOADING',
        [initDataSuccess.toString()]: () => 'SUCCESS',
        [initDataIdle.toString()]: () => 'IDLE',
    },
    initialState.status
);

const header = handleActions(
    {
        [initDataSuccess.toString()]: (state, action: { payload: PayloadInitDataType }) =>
            action.payload.header,
    },
    initialState.header
);

const footer = handleActions(
    {
        [initDataSuccess.toString()]: (state, action: { payload: PayloadInitDataType }) =>
            action.payload.footer,
    },
    initialState.footer
);

export const layouts = combineReducers({
    status,
    header,
    footer
});
