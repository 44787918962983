import { createActions } from 'redux-actions';

const {
    getTeam,
    getTeamSuccess,
    getTeamFailure,
    getTeamIdle,
}: {
    getTeam?: any;
    getTeamSuccess?: any;
    getTeamFailure?: any;
    getTeamIdle?: any;
} = createActions(
    'GET_TEAM',
    'GET_TEAM_SUCCESS',
    'GET_TEAM_FAILURE',
    'GET_TEAM_IDLE',
);

export {
    getTeam,
    getTeamSuccess,
    getTeamFailure,
    getTeamIdle,
};
