import { createActions } from "redux-actions";
import { PayloadOurFocusType } from "./types";

const { ourFocusFailure, ourFocusIdle, ourFocus } = createActions(
  "OUR_FOCUS_FAILURE",
  "OUR_FOCUS_IDLE",
  "OUR_FOCUS"
);

const { ourFocusSuccess } = createActions<PayloadOurFocusType>("OUR_FOCUS_SUCCESS");

export { ourFocusFailure, ourFocusIdle, ourFocus, ourFocusSuccess };
