import { useContext } from 'react';
import fetch from 'isomorphic-fetch';
import { apiUrl, token, Context } from '../../../../config'
import { Locales } from 'types';

export const getOurFocusRequest = (props: { locale: Locales }) => {
    const { locale } = props;
    const url = `${apiUrl}our-focus?locale=${locale}&populate[Steps][populate]=*&populate[Portfolio][populate]=*`;

    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return fetch(url, {
        method: 'GET',
        headers,
    })
        .then(response => response.json())
        .then(data => data.data);
};

