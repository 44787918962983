import { createActions } from "redux-actions";
import { PayloadInitDataType } from "./types";

const { initDataFailure, initDataIdle, initData } = createActions(
  "INIT_DATA_FAILURE",
  "INIT_DATA_IDLE",
  "INIT_DATA"
);

const { initDataSuccess } = createActions<PayloadInitDataType>("INIT_DATA_SUCCESS");

export { initDataFailure, initDataIdle, initData, initDataSuccess };
